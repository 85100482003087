/**
 * @generated SignedSource<<35d1018241a1de6d228b503f9f5bc5ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ResolvePeopleGpsCoordinatesInput = {
  clientMutationId?: string | null;
  personIds: ReadonlyArray<string>;
};
export type ResolvePeopleGpsCoordinatesButton_FetchMutation$variables = {
  input: ResolvePeopleGpsCoordinatesInput;
};
export type ResolvePeopleGpsCoordinatesButton_FetchMutation$data = {
  readonly Staff: {
    readonly resolvePeopleGpsCoordinates: {
      readonly updatedPeople: ReadonlyArray<{
        readonly address: {
          readonly latitude: number | null;
          readonly longitude: number | null;
        } | null;
        readonly id: string;
      }>;
    } | null;
  };
};
export type ResolvePeopleGpsCoordinatesButton_FetchMutation = {
  response: ResolvePeopleGpsCoordinatesButton_FetchMutation$data;
  variables: ResolvePeopleGpsCoordinatesButton_FetchMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "StaffMutations",
    "kind": "LinkedField",
    "name": "Staff",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "ResolvePeopleGpsCoordinatesPayload",
        "kind": "LinkedField",
        "name": "resolvePeopleGpsCoordinates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "updatedPeople",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Address",
                "kind": "LinkedField",
                "name": "address",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "latitude",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "longitude",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ResolvePeopleGpsCoordinatesButton_FetchMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResolvePeopleGpsCoordinatesButton_FetchMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0e13ab24a517183bdbbc6ac304830bc6",
    "id": null,
    "metadata": {},
    "name": "ResolvePeopleGpsCoordinatesButton_FetchMutation",
    "operationKind": "mutation",
    "text": "mutation ResolvePeopleGpsCoordinatesButton_FetchMutation(\n  $input: ResolvePeopleGpsCoordinatesInput!\n) {\n  Staff {\n    resolvePeopleGpsCoordinates(input: $input) {\n      updatedPeople {\n        id\n        address {\n          latitude\n          longitude\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5f20e384bf0690641a214d68a2fc43d6";

export default node;
