/**
 * @generated SignedSource<<eca268a95f991a1d14c574e9f7663e88>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StartScreen_Query$variables = {};
export type StartScreen_Query$data = {
  readonly Scenario: {
    readonly Scenarios: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        };
      } | null> | null;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"BaseScreen_QueryFragment">;
};
export type StartScreen_Query = {
  response: StartScreen_Query$data;
  variables: StartScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "ScenarioQueries",
  "kind": "LinkedField",
  "name": "Scenario",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Literal",
          "name": "onlyMaster",
          "value": true
        }
      ],
      "concreteType": "ScenariosConnection",
      "kind": "LinkedField",
      "name": "Scenarios",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ScenariosEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Scenario",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "Scenarios(first:1,onlyMaster:true)"
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StartScreen_Query",
    "selections": [
      (v1/*: any*/),
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "BaseScreen_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "StartScreen_Query",
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "currentAccount",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "extension",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "File",
                            "kind": "LinkedField",
                            "name": "logo",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              },
                              (v0/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "HarkinsAccountExtensionAndId",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8245cb5d67b91ebc89b6c32c921fa797",
    "id": null,
    "metadata": {},
    "name": "StartScreen_Query",
    "operationKind": "query",
    "text": "query StartScreen_Query {\n  Scenario {\n    Scenarios(first: 1, onlyMaster: true) {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n  }\n  ...BaseScreen_QueryFragment\n}\n\nfragment BaseHeader_LogoFragment on File {\n  url\n}\n\nfragment BaseScreen_QueryFragment on Query {\n  Viewer {\n    Auth {\n      currentAccount {\n        extension {\n          __typename\n          ... on HarkinsAccountExtensionAndId {\n            logo {\n              ...BaseHeader_LogoFragment\n              id\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "962ff2bdac126d5bc4c91f287e5d3d76";

export default node;
