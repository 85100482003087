import { type FormikState } from "formik";
import { classNames } from "primereact/utils";
import React, { type ReactNode } from "react";

import styled from "styled-components";

export interface ValidatedFieldProperties {
	required?: boolean;
	disabled?: boolean;
	placeholder?: string;

	step?: number;
	min?: number;
	max?: number;

	mode?: "decimal" | "currency" | undefined;
	locale?: string;
	id?: string;
}

export interface ValidatedFieldConfig<FieldType> extends ValidatedFieldProperties {
	fieldValue: FieldType | undefined;
	updateField: (newValue: FieldType | undefined) => void;
	fieldName?: string;
	required?: boolean;
	isValid?: boolean;
	disabled?: boolean;
	placeholder?: string;
}

export type FormikHookProps<State> = FormikState<State> & {
	setFieldTouched: (
		field: string,
		touched?: boolean,
		shouldValidate?: boolean | undefined,
	) => any;
	setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
};

export interface ValidatedFieldPropsV2<State, FieldType> extends ValidatedFieldProperties {
	label?: string;
	name: keyof State & string;

	formikConfig: FormikHookProps<State>;

	iconClass?: string;
	className?: string;
	helpText?: ReactNode;

	component: (renderConfig: ValidatedFieldConfig<FieldType>) => ReactNode;
	onChange?: (updatedValue: FieldType | undefined) => void;
}

export function ValidatedField<State, FieldType>({
	className,
	iconClass,
	name,
	label,
	formikConfig,
	helpText,
	component,
	required,
	onChange,
	...rest
}: ValidatedFieldPropsV2<State, FieldType>) {
	const hasError = formikConfig.errors[name];

	const value = formikConfig.values[name] as unknown as FieldType;

	const updateValue = (updatedValue: FieldType | undefined) => {
		formikConfig.setFieldTouched(name, true);
		formikConfig.setFieldValue(name, updatedValue);
		onChange?.(updatedValue);
	};

	const FieldContent = (
		<>
			{iconClass && <i className={`pi ${iconClass}`} />}

			{component({
				fieldValue: value,
				isValid: !hasError,
				fieldName: name,
				updateField: updateValue,
				required,
				...rest,
			})}
		</>
	);

	return (
		<div className={`field ${className ?? ""}`}>
			{label ? (
				<label htmlFor={name} className={classNames("mr-2", { "p-error": hasError })}>
					{label} {required ? <Required>*</Required> : ""}
				</label>
			) : null}

			{iconClass ? <span className="p-input-icon-right">{FieldContent}</span> : FieldContent}

			{hasError ? (
				<small className="p-error">{(formikConfig.errors as any)[name]}</small>
			) : null}

			{helpText ? <small>{helpText}</small> : null}
		</div>
	);
}

const Required = styled.span`
	color: var(--danger);
`;
