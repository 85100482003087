/**
 * @generated SignedSource<<b6fceca1256bb801e4d9bc2b50f1e1e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type accountSettingsScreen_Query$variables = {};
export type accountSettingsScreen_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ChangeLogoForm_CurrentUser" | "editAccountSettingsForm_QueryFragment" | "editAssessmentPasswordForm_QueryFragment" | "editNumericalDimensionExplanationsForm_QueryFragment">;
};
export type accountSettingsScreen_Query = {
  response: accountSettingsScreen_Query$data;
  variables: accountSettingsScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "accountSettingsScreen_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ChangeLogoForm_CurrentUser"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "editNumericalDimensionExplanationsForm_QueryFragment"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "editAccountSettingsForm_QueryFragment"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "editAssessmentPasswordForm_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "accountSettingsScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "currentAccount",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "extension",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "File",
                            "kind": "LinkedField",
                            "name": "logo",
                            "plural": false,
                            "selections": [
                              (v0/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "numericalDimensionExplanations",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "assessmentPassword",
                            "storageKey": null
                          }
                        ],
                        "type": "HarkinsAccountExtensionAndId",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountSettings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "force2FA",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2f451a6101d261ced738674d84ba231c",
    "id": null,
    "metadata": {},
    "name": "accountSettingsScreen_Query",
    "operationKind": "query",
    "text": "query accountSettingsScreen_Query {\n  ...ChangeLogoForm_CurrentUser\n  ...editNumericalDimensionExplanationsForm_QueryFragment\n  ...editAccountSettingsForm_QueryFragment\n  ...editAssessmentPasswordForm_QueryFragment\n}\n\nfragment ChangeLogoForm_CurrentUser on Query {\n  Viewer {\n    Auth {\n      currentAccount {\n        extension {\n          __typename\n          ... on HarkinsAccountExtensionAndId {\n            logo {\n              id\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment editAccountSettingsForm_QueryFragment on Query {\n  Viewer {\n    Auth {\n      currentAccount {\n        settings {\n          force2FA\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment editAssessmentPasswordForm_QueryFragment on Query {\n  Viewer {\n    Auth {\n      currentAccount {\n        extension {\n          __typename\n          ... on HarkinsAccountExtensionAndId {\n            assessmentPassword\n          }\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment editNumericalDimensionExplanationsForm_QueryFragment on Query {\n  Viewer {\n    Auth {\n      currentAccount {\n        extension {\n          __typename\n          ... on HarkinsAccountExtensionAndId {\n            numericalDimensionExplanations\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "006d5f3cd9a35452122cc837cb560956";

export default node;
