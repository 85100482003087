/**
 * @generated SignedSource<<9ed40a3368241f5e8a000a099112d1ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ScenarioStaffViewScreen_ScenarioFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardHeader_ScenarioFragment" | "StaffViewFiltersPart2_ScenarioFragment" | "updateAssignmentsFromDynamicsButton_ScenarioFragment">;
  readonly " $fragmentType": "ScenarioStaffViewScreen_ScenarioFragment";
};
export type ScenarioStaffViewScreen_ScenarioFragment$key = {
  readonly " $data"?: ScenarioStaffViewScreen_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ScenarioStaffViewScreen_ScenarioFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScenarioStaffViewScreen_ScenarioFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardHeader_ScenarioFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StaffViewFiltersPart2_ScenarioFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "updateAssignmentsFromDynamicsButton_ScenarioFragment"
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};

(node as any).hash = "80b14cad2c8b7f879df00fe09330a265";

export default node;
