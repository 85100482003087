import graphql from "babel-plugin-relay/macro";
import _, { isArray } from "lodash";
import React, { type ReactElement, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLazyLoadQuery } from "react-relay";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RedirectTo } from "./RedirectTo";
import { type RouteDefinition } from "./RouteDefinition";
import {
	type Permission,
	type PermissionBasedNavigation_Query,
} from "../__generated__/PermissionBasedNavigation_Query.graphql";
import { type CurrentUserData, setCurrentUser } from "../redux/CurrentUserSlice";

const PERMISSION_QUERY = graphql`
	query PermissionBasedNavigation_Query {
		Viewer {
			Auth {
				currentUser {
					permissionsInAccount
					user {
						name
						id
						extension {
							... on HarkinsUserExtensionAndId {
								showBudget
								preferredViewType
								showVolumePerPerson
							}
						}
					}
					accounts {
						id
						name
					}
				}
			}
		}
	}
`;

interface OwnProps {
	routes: RouteDefinition[];
	forbiddenRoute?: ReactElement;
}

export function PermissionBasedNavigation({ routes, forbiddenRoute }: OwnProps) {
	const query = useLazyLoadQuery<PermissionBasedNavigation_Query>(PERMISSION_QUERY, {});
	const dispatch = useDispatch();

	useEffect(() => {
		if (query.Viewer.Auth.currentUser) {
			dispatch(setCurrentUser(query.Viewer.Auth.currentUser as any as CurrentUserData));
		}
		// eslint-disable-next-line
	}, [query]);

	return (
		<BrowserRouter>
			<Routes>
				{filterByPermission(routes, !!query.Viewer.Auth.currentUser, [
					...(query.Viewer.Auth.currentUser?.permissionsInAccount ?? []),
				]).map((prd) => {
					return <Route key={prd.path} path={prd.path} element={prd.element} />;
				})}

				<Route
					key={"verboten"}
					path={"*"}
					element={
						forbiddenRoute || (
							<div>
								<RedirectTo to="/" />
							</div>
						)
					}
				/>
			</Routes>
		</BrowserRouter>
	);
}

export type RequiredPermissionsType =
	| Permission[]
	| "logged-in"
	| "logged-in-and-logged-out"
	| "only-logged-out";
export interface HasRequiredPermissionsType {
	requiredPermissions: RequiredPermissionsType;
}

export function filterByPermission<ItemType extends HasRequiredPermissionsType>(
	items: ItemType[],
	isLoggedIn: boolean,
	permissionsInAccount?: Permission[],
) {
	return items.filter((r) => {
		const loggedOutAndRouteRequiresLoggedOut =
			r.requiredPermissions === "only-logged-out" && !isLoggedIn;
		const loggedInAndRouteOnlyRequiredLoggedIn =
			r.requiredPermissions === "logged-in" && isLoggedIn;
		const loggedInOrLoggedOut = r.requiredPermissions === "logged-in-and-logged-out";

		// @ts-expect-error
		const permissionsAreKnown = permissionsInAccount && isArray(r.requiredPermissions);
		const isRoot = permissionsInAccount?.includes("AccountPermission_System_Root");
		const noRootPermissionRequiredAndUserIsOwner =
			!r.requiredPermissions.includes("AccountPermission_System_Root") &&
			permissionsInAccount?.includes("UserInAccountPermission_System_Owner");
		const isLoggedInAndHasAllNecessaryPermissions =
			permissionsAreKnown &&
			(_.difference(r.requiredPermissions, permissionsInAccount).length === 0 ||
				isRoot ||
				noRootPermissionRequiredAndUserIsOwner);

		return (
			loggedInOrLoggedOut ||
			loggedOutAndRouteRequiresLoggedOut ||
			loggedInAndRouteOnlyRequiredLoggedIn ||
			isLoggedInAndHasAllNecessaryPermissions
		);
	});
}
