/**
 * @generated SignedSource<<a76a50931f168b53cb49a709835cee16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LoginResponseStatusKind = "AuthCredentialsCorrect" | "TwoFactorAuthCredentialsIncorrect" | "TwoFactorAuthRequired";
export type LoginJwtInput = {
  clientMutationId?: string | null;
  email: string;
  password: string;
};
export type LoginForm_LoginMutation$variables = {
  input: LoginJwtInput;
};
export type LoginForm_LoginMutation$data = {
  readonly Auth: {
    readonly loginJwt: {
      readonly status: {
        readonly email?: string;
        readonly jwtTokens?: {
          readonly accessToken: string;
          readonly refreshToken: string;
        };
        readonly kind: LoginResponseStatusKind;
        readonly password?: string;
        readonly qrCodeUri?: string;
        readonly userName?: string;
      };
    } | null;
  };
};
export type LoginForm_LoginMutation = {
  response: LoginForm_LoginMutation$data;
  variables: LoginForm_LoginMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "password",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "qrCodeUri",
      "storageKey": null
    },
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/)
  ],
  "type": "TwoFactorAuthRequired",
  "abstractKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    (v5/*: any*/),
    (v4/*: any*/),
    (v3/*: any*/)
  ],
  "type": "twoFactorAuthCredentialsIncorrect",
  "abstractKey": null
},
v8 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "JwtLoginInformation",
      "kind": "LinkedField",
      "name": "jwtTokens",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "refreshToken",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accessToken",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "authCredentialsCorrect",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginForm_LoginMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthMutations",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "LoginJwtPayload",
            "kind": "LinkedField",
            "name": "loginJwt",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "status",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoginForm_LoginMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthMutations",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "LoginJwtPayload",
            "kind": "LinkedField",
            "name": "loginJwt",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "status",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "14e75f01c413caa9353d21011a45951e",
    "id": null,
    "metadata": {},
    "name": "LoginForm_LoginMutation",
    "operationKind": "mutation",
    "text": "mutation LoginForm_LoginMutation(\n  $input: LoginJwtInput!\n) {\n  Auth {\n    loginJwt(input: $input) {\n      status {\n        __typename\n        kind\n        ... on TwoFactorAuthRequired {\n          qrCodeUri\n          userName\n          password\n          email\n        }\n        ... on twoFactorAuthCredentialsIncorrect {\n          email\n          password\n          userName\n        }\n        ... on authCredentialsCorrect {\n          jwtTokens {\n            refreshToken\n            accessToken\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9c6b677ba8a29f292fcf146531c92422";

export default node;
