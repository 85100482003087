import React from "react";
import { AuthScreenBase } from "../../components/ui/AuthScreenBase";
import { LoginForm } from "../../components/relay/LoginForm";
import { TkComponentsContext } from "@thekeytechnology/framework-react-components";
import { HarkinsTheme } from "@corestyle/component-theme/component-theme";

export const LoginScreen = () => {
	return (
		<AuthScreenBase>
			<TkComponentsContext.Provider value={HarkinsTheme}>
				<div>
					<h1 className="text-center mb-6 text">Welcome back!</h1>
					<LoginForm />
				</div>
			</TkComponentsContext.Provider>
		</AuthScreenBase>
	);
};
