/**
 * @generated SignedSource<<0120b7448dfe78b211a5479593bff258>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssignmentRoleSelect_Query$variables = {
  alwaysIncludeIds?: ReadonlyArray<string> | null;
  filterByName?: string | null;
};
export type AssignmentRoleSelect_Query$data = {
  readonly Assignments: {
    readonly AssignmentRoles: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"AssignmentRoleSelect_AssignmentRoleFragment">;
        };
      } | null> | null;
    };
  };
};
export type AssignmentRoleSelect_Query = {
  response: AssignmentRoleSelect_Query$data;
  variables: AssignmentRoleSelect_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "alwaysIncludeIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterByName"
},
v2 = [
  {
    "kind": "Variable",
    "name": "alwaysIncludeIds",
    "variableName": "alwaysIncludeIds"
  },
  {
    "kind": "Variable",
    "name": "filterByName",
    "variableName": "filterByName"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssignmentRoleSelect_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AssignmentsQueries",
        "kind": "LinkedField",
        "name": "Assignments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "AssignmentRolesConnection",
            "kind": "LinkedField",
            "name": "AssignmentRoles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AssignmentRolesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssignmentRole",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineDataFragmentSpread",
                        "name": "AssignmentRoleSelect_AssignmentRoleFragment",
                        "selections": (v3/*: any*/),
                        "args": null,
                        "argumentDefinitions": []
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AssignmentRoleSelect_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AssignmentsQueries",
        "kind": "LinkedField",
        "name": "Assignments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "AssignmentRolesConnection",
            "kind": "LinkedField",
            "name": "AssignmentRoles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AssignmentRolesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssignmentRole",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b27f0adab1001cd63141e464548065d0",
    "id": null,
    "metadata": {},
    "name": "AssignmentRoleSelect_Query",
    "operationKind": "query",
    "text": "query AssignmentRoleSelect_Query(\n  $filterByName: String\n  $alwaysIncludeIds: [ID!]\n) {\n  Assignments {\n    AssignmentRoles(first: 20, filterByName: $filterByName, alwaysIncludeIds: $alwaysIncludeIds) {\n      edges {\n        node {\n          ...AssignmentRoleSelect_AssignmentRoleFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment AssignmentRoleSelect_AssignmentRoleFragment on AssignmentRole {\n  id\n  name\n}\n"
  }
};
})();

(node as any).hash = "5502e7ebb4a5d7ddfa1f55e8b033739c";

export default node;
