import { Form } from "@thekeytechnology/framework-react-components";
import graphql from "babel-plugin-relay/macro";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import React from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "react-relay";
import { NavLink, useNavigate } from "react-router-dom";
import { match } from "ts-pattern";
import * as Yup from "yup";
import {
	TWO_FACTOR_AUTH_OTP_ROUTE,
	type TwoFactorAuthOtpState,
} from "@screens/two-factor-auth-otp";
import { TWO_FACTOR_AUTH_SETUP_ROUTE } from "@screens/two-factor-auth-setup";
import {
	type LoginForm_LoginMutation,
	type LoginForm_LoginMutation$data,
} from "../../__generated__/LoginForm_LoginMutation.graphql";
import { setLoggedIn } from "../../redux/AuthSlice";
import { TkButton } from "../ui/TkButton";
import { TkButtonLink } from "../ui/TkButtonLink";
import { ValidatedField } from "../ui/ValidatedField";

const LOGIN_MUTATION = graphql`
	mutation LoginForm_LoginMutation($input: LoginJwtInput!) {
		Auth {
			loginJwt(input: $input) {
				status {
					kind
					... on TwoFactorAuthRequired {
						qrCodeUri
						userName
						password
						email
					}
					... on twoFactorAuthCredentialsIncorrect {
						email
						password
						userName
					}
					... on authCredentialsCorrect {
						jwtTokens {
							refreshToken
							accessToken
						}
					}
				}
			}
		}
	}
`;

interface FormState {
	email: string;
	password: string;
}

export const LoginForm = () => {
	const dispatch = useDispatch();
	const [login, isLoggingIn] = useMutation<LoginForm_LoginMutation>(LOGIN_MUTATION);
	const navigate = useNavigate();

	const handleLoginJWT = (response: LoginForm_LoginMutation$data) => {
		const tokenData = response.Auth.loginJwt?.status.jwtTokens;
		if (!tokenData) return;
		dispatch(
			setLoggedIn({
				tokenData,
			}),
		);
	};
	const handleRedirect2FALogin = (response: LoginForm_LoginMutation$data) => {
		const email = response.Auth.loginJwt?.status.email ?? "";
		const password = response.Auth.loginJwt?.status.password ?? "";

		navigate(TWO_FACTOR_AUTH_OTP_ROUTE, {
			state: { email, password } as TwoFactorAuthOtpState,
		});
	};

	const handleRedirect2FASetup = (response: LoginForm_LoginMutation$data) => {
		const email = response.Auth.loginJwt?.status.email ?? "";
		const password = response.Auth.loginJwt?.status.password ?? "";
		navigate(
			TWO_FACTOR_AUTH_SETUP_ROUTE.replace(":email", email).replace(":password", password),
			{
				state: {
					qrCodeUri: response.Auth.loginJwt?.status.qrCodeUri,
					password,
					email,
				},
			},
		);
	};

	const formik = useFormik<FormState>({
		initialValues: {
			email: "",
			password: "",
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email("Please enter a valid e-mail address.")
				.required("E-Mail is a required field."),
			password: Yup.string()
				.min(8, "A password needs at least 8 characters.")
				.required("Password is a required field."),
		}),
		onSubmit: (data) => {
			login({
				variables: {
					input: {
						email: data.email,
						password: data.password,
					},
				},
				onCompleted: (response) => {
					match(response.Auth.loginJwt?.status.kind)
						.with("TwoFactorAuthRequired", () => {
							handleRedirect2FASetup(response);
						})
						.with("TwoFactorAuthCredentialsIncorrect", () => {
							handleRedirect2FALogin(response);
						})
						.with("AuthCredentialsCorrect", () => {
							handleLoginJWT(response);
						});
				},
			});
		},
	});

	return (
		<Form onSubmit={formik.handleSubmit} className={"px-6 mb-6"}>
			<ValidatedField<FormState, string>
				className="mb-4"
				name={"email"}
				label={"E-Mail"}
				required={true}
				formikConfig={formik}
				component={({ fieldValue, updateField, fieldName, isValid }) => {
					return (
						<InputText
							id={fieldName}
							name={fieldName}
							value={fieldValue}
							onChange={(e) => {
								updateField(e.target.value);
							}}
							className={classNames({ "p-invalid": !isValid })}
						/>
					);
				}}
			/>
			<ValidatedField<FormState, string>
				className="mb-6"
				name={"password"}
				label={"Password"}
				required={true}
				formikConfig={formik}
				component={({ fieldValue, updateField, fieldName, isValid }) => {
					return (
						<Password
							id={fieldName}
							name={fieldName}
							value={fieldValue}
							onChange={(e) => {
								updateField(e.target.value);
							}}
							toggleMask
							feedback={false}
							className={classNames({ "p-invalid": !isValid })}
						/>
					);
				}}
			/>
			<TkButton disabled={isLoggingIn} type="submit" label="Sign In" className="p-mt-2" />
			<div className="mt-5">
				<NavLink to={"/forgot-password"}>
					<TkButtonLink label={"I forgot my password..."} />
				</NavLink>
			</div>
		</Form>
	);
};
