import {ReactNode} from "react";
import {BaseHeader} from "./BaseHeader";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {BaseScreen_QueryFragment$key} from "../../__generated__/BaseScreen_QueryFragment.graphql";

const FRAGMENT = graphql`
    fragment BaseScreen_QueryFragment on Query {
        Viewer {
            Auth {
                currentAccount {
                    extension {
                        ... on HarkinsAccountExtensionAndId {
                            logo {
                                ...BaseHeader_LogoFragment
                            }
                        }
                    }
                }
            }
        }
    }
`

interface OwnProps {
    children?: ReactNode
    headerComponents?: ReactNode
    queryFragmentRef: BaseScreen_QueryFragment$key
}


export const BaseScreen = ({children, headerComponents, queryFragmentRef}: OwnProps) => {
    const queryFragment = useFragment<BaseScreen_QueryFragment$key>(FRAGMENT, queryFragmentRef)
    return <div className="background p-5 flex flex-column h-full">
        <BaseHeader queryFragmentRef={queryFragment.Viewer.Auth.currentAccount?.extension.logo || undefined}
                    className="mb-4"
                    children={headerComponents}/>

        <div style={{maxHeight: "calc(100vh - 61px - 6rem)", height: "calc(100vh - 61px - 6rem)"}}>
            {children}
        </div>
    </div>

}
