import { graphql } from "babel-plugin-relay/macro";
import {useMatch} from "react-router-dom";
import {useEffect} from "react";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import { useDispatch } from "react-redux";
import {useFragment, useLazyLoadQuery} from "react-relay";
import {ProjectMapPart} from "./parts/ProjectMap.part";
import {type ScenarioMapViewScreen_Query} from "../../__generated__/ScenarioMapViewScreen_Query.graphql";
import { type ScenarioMapViewScreen_ScenarioFragment$key } from "../../__generated__/ScenarioMapViewScreen_ScenarioFragment.graphql";
import { DashboardHeader } from "../../components/relay/DashboardHeader";
import { BaseScreen } from "../../components/ui/BaseScreen";
import { RedirectTo } from "../../navigation/RedirectTo";
import { setSelectedProjectId } from "../../redux/ProjectViewSlice";
import { RosterPart } from "../project-view/parts/roster-part";

const QUERY = graphql`
	query ScenarioMapViewScreen_Query(
		$id: ID!
		$filterByName: String
		$filterByAssignmentRoles: [ID!]
		$filterByUtilizationStatus: [UtilizationStatus!]
		$filterBySalaryMinimum: BigDecimal
		$filterBySalaryMaximum: BigDecimal
		$filterByFreeDateMinimum: LocalDate
		$filterByFreeDateMaximum: LocalDate
		$filterByAllocatedDateMinimum: LocalDate
		$filterByAllocatedDateMaximum: LocalDate
		$filterByGapDaysMinimum: Int
		$filterByGapDaysMaximum: Int
		$filterByDistanceMinimum: Int
		$filterByDistanceMaximum: Int
		$sortByClosestToProject: ID
		$utilizationWindow: UtilizationWindowInput
	) {
		node(id: $id) {
			... on Scenario {
				...ScenarioMapViewScreen_ScenarioFragment
			}
		}
		...rosterPart_StaffFragment
			@arguments(
				filterByName: $filterByName
				scenarioRef: $id
				filterByAssignmentRoles: $filterByAssignmentRoles
				filterByUtilizationStatus: $filterByUtilizationStatus
				filterBySalaryMinimum: $filterBySalaryMinimum
				filterBySalaryMaximum: $filterBySalaryMaximum
				filterByFreeDateMinimum: $filterByFreeDateMinimum
				filterByFreeDateMaximum: $filterByFreeDateMaximum
				filterByAllocatedDateMinimum: $filterByAllocatedDateMinimum
				filterByAllocatedDateMaximum: $filterByAllocatedDateMaximum
				filterByGapDaysMinimum: $filterByGapDaysMinimum
				filterByGapDaysMaximum: $filterByGapDaysMaximum
				filterByDistanceMinimum: $filterByDistanceMinimum
				filterByDistanceMaximum: $filterByDistanceMaximum
				sortByClosestToProject: $sortByClosestToProject
				utilizationWindow: $utilizationWindow
			)
		...BaseScreen_QueryFragment
		...rosterPart_FilterFragment
	}
`;
const SCENARIO_QUERY = graphql`
	fragment ScenarioMapViewScreen_ScenarioFragment on Scenario {
		id
		...ProjectMapPart_ScenarioFragment
		...rosterPart_ScenarioFragment
		...DashboardHeader_ScenarioFragment
		projects {
			edges {
				node {
					id
					project {
						id
					}
					...ProjectMapPart_ProjectInScenarioFragment
				}
			}
		}
	}
`;

export const SCENARIO_MAP_VIEW_SCREEN_ROUTE = "/scenarios/:scenarioId/map/:projectId";

export const ScenarioMapViewScreen = () => {
	const {
		params: { scenarioId, projectId },
	} = useMatch(SCENARIO_MAP_VIEW_SCREEN_ROUTE)!;

	const dispatch = useDispatch();

	const query = useLazyLoadQuery<ScenarioMapViewScreen_Query>(QUERY, {
		id: scenarioId!,
		sortByClosestToProject: projectId,
		utilizationWindow: undefined,
	});

	const scenario = useFragment<ScenarioMapViewScreen_ScenarioFragment$key>(
		SCENARIO_QUERY,
		query.node,
	);

	const project = scenario?.projects.edges?.find((e) => e?.node.id === projectId)?.node;

	useEffect(() => {
		if (project) {
			dispatch(setSelectedProjectId(project.project?.id));
		}
		// eslint-disable-next-line
	}, [project]);

	return scenario && project ? (
		<BaseScreen
			queryFragmentRef={query}
			headerComponents={<DashboardHeader scenarioFragmentRef={scenario} />}
		>
			<DndProvider backend={HTML5Backend}>
				<div className="flex h-full">
					<div
						className="flex-grow-0 mr-5 h-full hide-print"
						style={{ minWidth: 300, maxWidth: 300 }}
					>
						<RosterPart
							className="h-full"
							scenarioFragmentRef={scenario}
							staffFragmentRef={query}
							queryRef={query}
						/>
					</div>

					<div className="flex-grow-1 h-full">
						<ProjectMapPart
							scenarioFragmentRef={scenario}
							projectFragmentRef={project}
						/>
					</div>
				</div>
			</DndProvider>
		</BaseScreen>
	) : (
		<RedirectTo to={"/"} />
	);
};
