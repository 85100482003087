import { graphql } from "babel-plugin-relay/macro";

export const SET_ACCOUNT_SETTINGS_MUTATION = graphql`
	mutation editAccountSettingsForm_SetAccountSettingsMutation($input: SetAccountSettingsInput!) {
		Admin {
			Auth {
				setAccountSettings(input: $input) {
					account {
						settings {
							force2FA
						}
					}
					clientMutationId
				}
			}
		}
	}
`;

export const QUERY_FRAGMENT = graphql`
	fragment editAccountSettingsForm_QueryFragment on Query {
		Viewer {
			Auth {
				currentAccount {
					settings {
						force2FA
					}
				}
			}
		}
	}
`;
