/**
 * @generated SignedSource<<eacc2e6fe086a62d24a05bf3ac5cf6a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Permission = "AccountPermission_Auth_DriveTimesEnabled" | "AccountPermission_Auth_Field" | "AccountPermission_Auth_GapDaysEnabled" | "AccountPermission_Auth_PreConIntegration" | "AccountPermission_Auth_Reports" | "AccountPermission_Rand_DataWarehouseIntegration" | "AccountPermission_System_Root" | "UserInAccountPermission_Assessment_Edit" | "UserInAccountPermission_AssignmentRole_Edit" | "UserInAccountPermission_Division_Edit" | "UserInAccountPermission_Files_Delete" | "UserInAccountPermission_Management_Management" | "UserInAccountPermission_Maps_Read" | "UserInAccountPermission_Precon_Edit" | "UserInAccountPermission_PrivateData_Read" | "UserInAccountPermission_ProjectStages_Edit" | "UserInAccountPermission_Project_Edit" | "UserInAccountPermission_Region_Edit" | "UserInAccountPermission_Salary_Read" | "UserInAccountPermission_Scenario_Budgets" | "UserInAccountPermission_Scenario_Edit" | "UserInAccountPermission_Scenario_Masterplan" | "UserInAccountPermission_Skills_Edit" | "UserInAccountPermission_Skills_Read" | "UserInAccountPermission_Staff_Edit" | "UserInAccountPermission_System_Owner" | "UserInAccountPermission_Tasks_Tasks" | "UserInAccountPermission_Templates_Edit" | "UserInAccountPermission_Utilization_Read";
export type PreferredViewType = "None" | "ProjectView" | "StaffView";
export type PermissionBasedNavigation_Query$variables = {};
export type PermissionBasedNavigation_Query$data = {
  readonly Viewer: {
    readonly Auth: {
      readonly currentUser: {
        readonly accounts: ReadonlyArray<{
          readonly id: string;
          readonly name: string;
        }>;
        readonly permissionsInAccount: ReadonlyArray<Permission>;
        readonly user: {
          readonly extension: {
            readonly preferredViewType?: PreferredViewType;
            readonly showBudget?: boolean;
            readonly showVolumePerPerson?: boolean;
          };
          readonly id: string;
          readonly name: string;
        };
      } | null;
    };
  };
};
export type PermissionBasedNavigation_Query = {
  response: PermissionBasedNavigation_Query$data;
  variables: PermissionBasedNavigation_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "permissionsInAccount",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showBudget",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "preferredViewType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showVolumePerPerson",
      "storageKey": null
    }
  ],
  "type": "HarkinsUserExtensionAndId",
  "abstractKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Account",
  "kind": "LinkedField",
  "name": "accounts",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v1/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PermissionBasedNavigation_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extension",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PermissionBasedNavigation_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extension",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b58275029d311c7cd004dfc1c467f339",
    "id": null,
    "metadata": {},
    "name": "PermissionBasedNavigation_Query",
    "operationKind": "query",
    "text": "query PermissionBasedNavigation_Query {\n  Viewer {\n    Auth {\n      currentUser {\n        permissionsInAccount\n        user {\n          name\n          id\n          extension {\n            __typename\n            ... on HarkinsUserExtensionAndId {\n              showBudget\n              preferredViewType\n              showVolumePerPerson\n            }\n          }\n        }\n        accounts {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6fed2ad53f3e667c1c19d2c28ceb8a2a";

export default node;
