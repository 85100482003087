/**
 * @generated SignedSource<<34965efaab6069413a49af118b67ae13>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BaseSettingsScreen_Query$variables = {};
export type BaseSettingsScreen_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BaseScreen_QueryFragment">;
};
export type BaseSettingsScreen_Query = {
  response: BaseSettingsScreen_Query$data;
  variables: BaseSettingsScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BaseSettingsScreen_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "BaseScreen_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BaseSettingsScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "currentAccount",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "extension",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "File",
                            "kind": "LinkedField",
                            "name": "logo",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              },
                              (v0/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "HarkinsAccountExtensionAndId",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2592bf851ac8fbd7dd74835936bd020d",
    "id": null,
    "metadata": {},
    "name": "BaseSettingsScreen_Query",
    "operationKind": "query",
    "text": "query BaseSettingsScreen_Query {\n  ...BaseScreen_QueryFragment\n}\n\nfragment BaseHeader_LogoFragment on File {\n  url\n}\n\nfragment BaseScreen_QueryFragment on Query {\n  Viewer {\n    Auth {\n      currentAccount {\n        extension {\n          __typename\n          ... on HarkinsAccountExtensionAndId {\n            logo {\n              ...BaseHeader_LogoFragment\n              id\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "46c8538e905cadf540265cef364841e0";

export default node;
