import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import {TkButton} from "../ui/TkButton";
import {
    ResolvePeopleGpsCoordinatesButton_FetchMutation
} from "../../__generated__/ResolvePeopleGpsCoordinatesButton_FetchMutation.graphql";

const IMPORT_MUTATION = graphql`
    mutation ResolvePeopleGpsCoordinatesButton_FetchMutation($input: ResolvePeopleGpsCoordinatesInput!) {
        Staff {
            resolvePeopleGpsCoordinates(input: $input) {
                updatedPeople {
                    id
                    address {
                        latitude
                        longitude
                    }
                }
            }
        }
    }
`

interface OwnProps {
    selectedPeopleIds: string[]
    className?: string
}


export const ResolvePeopleGpsCoordinatesButton = ({className, selectedPeopleIds}: OwnProps) => {
    const [resolveGpsCoordinates, isResolving] = useMutation<ResolvePeopleGpsCoordinatesButton_FetchMutation>(IMPORT_MUTATION)
    return <TkButton
        className={className}
        icon="pi pi-replay"
        iconPos="left"
        tooltip={"You can use this to fix incomplete address warnings in the user table."}
        label={isResolving ? "Resolving..." : "Resolve GPS coordinates"}
        disabled={isResolving || selectedPeopleIds.length === 0}
        onClick={() => resolveGpsCoordinates({
            variables: {
                input: {
                    personIds: selectedPeopleIds
                }
            }
        })}
    />
}


