import { Button } from "@thekeytechnology/framework-react-components";
import { OverlayPanel } from "primereact/overlaypanel";
import { Fragment, useRef } from "react";
import { match } from "ts-pattern";
import { Wrapper } from "@components/context-menu/context-menu.styles";
import {
	ContextMenuKind,
	type ContextMenuProps,
} from "@components/context-menu/context-menu.types";

export const ContextMenu = ({ options }: ContextMenuProps) => {
	const ref = useRef<OverlayPanel>(null);
	return (
		<>
			<Button
				inputVariant={"subtle"}
				content={{ icon: "pi pi-ellipsis-h" }}
				onClick={(e) => ref.current?.toggle(e)}
			/>
			<OverlayPanel ref={ref}>
				<Wrapper>
					{options.map(
						({ label, icon, iconPosition, onClick, kind, node, inputVariant }, index) =>
							match(kind)
								.with(ContextMenuKind.normal, () => (
									<Button
										content={{
											icon,
											label,
											iconPosition,
										}}
										key={"item" + index}
										onClick={onClick}
										inputVariant={inputVariant ?? "subtle"}
									/>
								))
								.with(ContextMenuKind.override, () => node)
								.otherwise(() => <Fragment />),
					)}
				</Wrapper>
			</OverlayPanel>
		</>
	);
};
