import {
	selectScenarioProjectFilters,
	setPage,
	setProjectViewProjectFilters,
} from "../../../redux/ProjectViewSlice";
import { TabPanel } from "primereact/tabview";
import styled from "styled-components";
import { TkTabView } from "../../../components/ui/TkTabView";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ProjectStagesTabs_ProjectStages$key } from "../../../__generated__/ProjectStagesTabs_ProjectStages.graphql";
import { useDispatch, useSelector } from "react-redux";

const FRAGMENT = graphql`
	fragment ProjectStagesTabs_ProjectStages on Query {
		Project {
			ProjectStages(first: 100) {
				edges {
					node {
						id
						name
						sortOrder
					}
				}
			}
		}
	}
`;

interface OwnProps {
	projectStagesFragmentRef: ProjectStagesTabs_ProjectStages$key;
}

export const ProjectStagesTabs = ({ projectStagesFragmentRef }: OwnProps) => {
	const projectStages =
		useFragment<ProjectStagesTabs_ProjectStages$key>(
			FRAGMENT,
			projectStagesFragmentRef,
		).Project.ProjectStages.edges?.map((e) => e!.node!) || [];
	const projectFilters = useSelector(selectScenarioProjectFilters);

	const dispatch = useDispatch();

	return (
		<ProjectListTabView
			className="mr-2"
			activeIndex={
				projectStages?.findIndex((s) => projectFilters.filterByStage === s.id) + 1 || 0
			}
			onTabChange={(e) => {
				dispatch(
					setProjectViewProjectFilters({
						...projectFilters,
						filterByStage: e.index === 0 ? undefined : projectStages[e.index - 1]?.id,
					}),
				);
				dispatch(setPage(0));
			}}
		>
			<TabPanel header="All projects"></TabPanel>

			{projectStages.map((stage) => {
				return <TabPanel key={stage.id} header={stage.name}></TabPanel>;
			})}
		</ProjectListTabView>
	);
};

const ProjectListTabView = styled(TkTabView)`
	.p-tabview-nav {
		background-color: transparent;
	}

	.p-unselectable-text:not(.p-tabview-selected) a {
		background-color: transparent;
	}

	.p-tabview-panels {
		background-color: transparent;
		display: none;
	}
`;
