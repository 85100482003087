/**
 * @generated SignedSource<<c11b750550e978b74d12998b6c96770a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editAccountSettingsForm_QueryFragment$data = {
  readonly Viewer: {
    readonly Auth: {
      readonly currentAccount: {
        readonly settings: {
          readonly force2FA: boolean;
        };
      } | null;
    };
  };
  readonly " $fragmentType": "editAccountSettingsForm_QueryFragment";
};
export type editAccountSettingsForm_QueryFragment$key = {
  readonly " $data"?: editAccountSettingsForm_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editAccountSettingsForm_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editAccountSettingsForm_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewerQueryType",
      "kind": "LinkedField",
      "name": "Viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AuthViewerSchema",
          "kind": "LinkedField",
          "name": "Auth",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Account",
              "kind": "LinkedField",
              "name": "currentAccount",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AccountSettings",
                  "kind": "LinkedField",
                  "name": "settings",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "force2FA",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "0d540983836cf91a216625645331c898";

export default node;
