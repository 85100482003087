/**
 * @generated SignedSource<<4a376ab91e94697a5fabc70abc382b55>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ResolveProjectsGpsCoordinatesInput = {
  clientMutationId?: string | null;
  projectIds: ReadonlyArray<string>;
};
export type ResolveProjectsGpsCoordinatesButton_FetchMutation$variables = {
  input: ResolveProjectsGpsCoordinatesInput;
};
export type ResolveProjectsGpsCoordinatesButton_FetchMutation$data = {
  readonly Project: {
    readonly resolveProjectsGpsCoordinates: {
      readonly updatedProjects: ReadonlyArray<{
        readonly address: {
          readonly latitude: number | null;
          readonly longitude: number | null;
        } | null;
        readonly id: string;
      }>;
    } | null;
  };
};
export type ResolveProjectsGpsCoordinatesButton_FetchMutation = {
  response: ResolveProjectsGpsCoordinatesButton_FetchMutation$data;
  variables: ResolveProjectsGpsCoordinatesButton_FetchMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ProjectMutations",
    "kind": "LinkedField",
    "name": "Project",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "ResolveProjectsGpsCoordinatesPayload",
        "kind": "LinkedField",
        "name": "resolveProjectsGpsCoordinates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "updatedProjects",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Address",
                "kind": "LinkedField",
                "name": "address",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "latitude",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "longitude",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ResolveProjectsGpsCoordinatesButton_FetchMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResolveProjectsGpsCoordinatesButton_FetchMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7b359c4dc25bd185292ce9e689f3aab2",
    "id": null,
    "metadata": {},
    "name": "ResolveProjectsGpsCoordinatesButton_FetchMutation",
    "operationKind": "mutation",
    "text": "mutation ResolveProjectsGpsCoordinatesButton_FetchMutation(\n  $input: ResolveProjectsGpsCoordinatesInput!\n) {\n  Project {\n    resolveProjectsGpsCoordinates(input: $input) {\n      updatedProjects {\n        id\n        address {\n          latitude\n          longitude\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1b6425103bda9414fcaa60509f2788d0";

export default node;
